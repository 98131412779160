<template>
  <section id="attendance">
    <div class="container">
      <div class="heading" data-aos="fade-right">
        <h2 class="title font-secondary fs-40 text-teal-dark text-center">
          Buku Tamu
        </h2>
      </div>

      <div class="row justify-content-center" data-aos="fade-left">
        <div class="col-lg-6">
          <div class="row">
            <div class="col-sm-6">
              <div
                class="form-group required"
                :class="{ disabled: $root.guest.name }"
              >
                <label for="name">Nama</label>
                <input
                  type="text"
                  id="name"
                  class="form-control"
                  v-model="name"
                  :disabled="$root.guest.name"
                  @keyup="validate"
                />
                <div class="form-help" v-if="errors.name">
                  {{ errors.name }}
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group required">
                <label for="attendance">Kehadiran</label>
                <select id="attendance" class="form-control" v-model="absence">
                  <option value="going">Hadir</option>
                  <option value="not_going">Tidak Hadir</option>
                  <option value="maybe">Masih Ragu</option>
                </select>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label for="greeting">Ucapan</label>
                <textarea
                  class="form-control"
                  id="greeting"
                  v-model="message"
                ></textarea>
              </div>
            </div>
            <div class="col-12">
              <transition name="fade">
                <div class="alert alert-success" v-if="successMessage">
                  Thank you! Ucapannya sudah kami terima 😊
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>

      <div class="text-center mt-3">
        <button
          type="button"
          class="btn btn-primary btn-md d-block w-100"
          :class="{ loading }"
          @click="submit"
        >
          <i class="loading-icon material-icons">refresh</i>
          <span class="text">Kirim Ucapan</span>
        </button>

        <!-- <div class="max-w-screen-md mx-auto">
          <LoveGift />
        </div> -->
      </div>

      <div class="rsvps-list">
        <div
          v-for="(data, index) in rsvps"
          :key="`${data.name}_${index}`"
          class="rsvp-item"
        >
          <div class="mr-3">
            <svg
              v-if="index % 9 === 0"
              viewBox="0 0 36 36"
              fill="none"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              width="60"
            >
              <title>Bessie Coleman</title>
              <mask
                id="mask__beam"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="36"
                height="36"
              >
                <rect width="36" height="36" rx="72" fill="#FFFFFF" />
              </mask>
              <g mask="url(#mask__beam)">
                <rect width="36" height="36" fill="#bda2a2" />
                <rect
                  x="0"
                  y="0"
                  width="36"
                  height="36"
                  transform="translate(-4 -4) rotate(188 18 18) scale(1.2)"
                  fill="#fad889"
                  rx="36"
                />
                <g transform="translate(-4 -2) rotate(-8 18 18)">
                  <path
                    d="M15 21c2 1 4 1 6 0"
                    stroke="#000000"
                    fill="none"
                    stroke-linecap="round"
                  />
                  <rect
                    x="11"
                    y="14"
                    width="1.5"
                    height="2"
                    rx="1"
                    stroke="none"
                    fill="#000000"
                  />
                  <rect
                    x="23"
                    y="14"
                    width="1.5"
                    height="2"
                    rx="1"
                    stroke="none"
                    fill="#000000"
                  />
                </g>
              </g>
            </svg>
            <svg
              v-else-if="index % 9 === 1"
              viewBox="0 0 36 36"
              fill="none"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              width="60"
            >
              <title>Elizabeth Peratrovich</title>
              <mask
                id="mask__beam"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="36"
                height="36"
              >
                <rect width="36" height="36" rx="72" fill="#FFFFFF" />
              </mask>
              <g mask="url(#mask__beam)">
                <rect width="36" height="36" fill="#fbbe9a" />
                <rect
                  x="0"
                  y="0"
                  width="36"
                  height="36"
                  transform="translate(9 -5) rotate(219 18 18) scale(1)"
                  fill="#faf5c8"
                  rx="6"
                />
                <g transform="translate(4.5 -4) rotate(9 18 18)">
                  <path
                    d="M15 19c2 1 4 1 6 0"
                    stroke="#000000"
                    fill="none"
                    stroke-linecap="round"
                  />
                  <rect
                    x="10"
                    y="14"
                    width="1.5"
                    height="2"
                    rx="1"
                    stroke="none"
                    fill="#000000"
                  />
                  <rect
                    x="24"
                    y="14"
                    width="1.5"
                    height="2"
                    rx="1"
                    stroke="none"
                    fill="#000000"
                  />
                </g>
              </g>
            </svg>
            <svg
              v-else-if="index % 9 === 2"
              viewBox="0 0 36 36"
              fill="none"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              width="60"
            >
              <title>Mother Frances</title>
              <mask
                id="mask__beam"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="36"
                height="36"
              >
                <rect width="36" height="36" rx="72" fill="#FFFFFF" />
              </mask>
              <g mask="url(#mask__beam)">
                <rect width="36" height="36" fill="#fbbe9a" />
                <rect
                  x="0"
                  y="0"
                  width="36"
                  height="36"
                  transform="translate(-5 9) rotate(229 18 18) scale(1.1)"
                  fill="#faf5c8"
                  rx="36"
                />
                <g transform="translate(-5 4.5) rotate(9 18 18)">
                  <path d="M13,20 a1,0.75 0 0,0 10,0" fill="#000000" />
                  <rect
                    x="10"
                    y="14"
                    width="1.5"
                    height="2"
                    rx="1"
                    stroke="none"
                    fill="#000000"
                  />
                  <rect
                    x="24"
                    y="14"
                    width="1.5"
                    height="2"
                    rx="1"
                    stroke="none"
                    fill="#000000"
                  />
                </g>
              </g>
            </svg>
            <svg
              v-else-if="index % 9 === 3"
              viewBox="0 0 36 36"
              fill="none"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              width="60"
            >
              <title>Phillis Wheatley</title>
              <mask
                id="mask__beam"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="36"
                height="36"
              >
                <rect width="36" height="36" rx="72" fill="#FFFFFF" />
              </mask>
              <g mask="url(#mask__beam)">
                <rect width="36" height="36" fill="#fbbe9a" />
                <rect
                  x="0"
                  y="0"
                  width="36"
                  height="36"
                  transform="translate(8 8) rotate(214 18 18) scale(1.1)"
                  fill="#faf5c8"
                  rx="6"
                />
                <g transform="translate(4 4) rotate(-4 18 18)">
                  <path d="M13,20 a1,0.75 0 0,0 10,0" fill="#000000" />
                  <rect
                    x="10"
                    y="14"
                    width="1.5"
                    height="2"
                    rx="1"
                    stroke="none"
                    fill="#000000"
                  />
                  <rect
                    x="24"
                    y="14"
                    width="1.5"
                    height="2"
                    rx="1"
                    stroke="none"
                    fill="#000000"
                  />
                </g>
              </g>
            </svg>
            <svg
              v-else-if="index % 9 === 4"
              viewBox="0 0 36 36"
              fill="none"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              width="60"
            >
              <title>Ellen Swallow</title>
              <mask
                id="mask__beam"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="36"
                height="36"
              >
                <rect width="36" height="36" rx="72" fill="#FFFFFF" />
              </mask>
              <g mask="url(#mask__beam)">
                <rect width="36" height="36" fill="#fbbe9a" />
                <rect
                  x="0"
                  y="0"
                  width="36"
                  height="36"
                  transform="translate(9 -5) rotate(219 18 18) scale(1)"
                  fill="#faf5c8"
                  rx="6"
                />
                <g transform="translate(4.5 -3) rotate(-9 18 18)">
                  <path
                    d="M15 19c2 1 4 1 6 0"
                    stroke="#000000"
                    fill="none"
                    stroke-linecap="round"
                  />
                  <rect
                    x="10"
                    y="14"
                    width="1.5"
                    height="2"
                    rx="1"
                    stroke="none"
                    fill="#000000"
                  />
                  <rect
                    x="24"
                    y="14"
                    width="1.5"
                    height="2"
                    rx="1"
                    stroke="none"
                    fill="#000000"
                  />
                </g>
              </g>
            </svg>
            <svg
              v-else-if="index % 9 === 5"
              viewBox="0 0 36 36"
              fill="none"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              width="60"
            >
              <title>Harriet Tubman</title>
              <mask
                id="mask__beam"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="36"
                height="36"
              >
                <rect width="36" height="36" rx="72" fill="#FFFFFF" />
              </mask>
              <g mask="url(#mask__beam)">
                <rect width="36" height="36" fill="#bda2a2" />
                <rect
                  x="0"
                  y="0"
                  width="36"
                  height="36"
                  transform="translate(8 8) rotate(278 18 18) scale(1.2)"
                  fill="#fad889"
                  rx="6"
                />
                <g transform="translate(4 4) rotate(-8 18 18)">
                  <path d="M13,21 a1,0.75 0 0,0 10,0" fill="#000000" />
                  <rect
                    x="11"
                    y="14"
                    width="1.5"
                    height="2"
                    rx="1"
                    stroke="none"
                    fill="#000000"
                  />
                  <rect
                    x="23"
                    y="14"
                    width="1.5"
                    height="2"
                    rx="1"
                    stroke="none"
                    fill="#000000"
                  />
                </g>
              </g>
            </svg>
            <svg
              v-else-if="index % 9 === 6"
              viewBox="0 0 36 36"
              fill="none"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              width="60"
            >
              <title>Lyda Conley</title>
              <mask
                id="mask__beam"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="36"
                height="36"
              >
                <rect width="36" height="36" rx="72" fill="#FFFFFF" />
              </mask>
              <g mask="url(#mask__beam)">
                <rect width="36" height="36" fill="#fbbe9a" />
                <rect
                  x="0"
                  y="0"
                  width="36"
                  height="36"
                  transform="translate(0 8) rotate(324 18 18) scale(1)"
                  fill="#faf5c8"
                  rx="36"
                />
                <g transform="translate(-4 4) rotate(4 18 18)">
                  <path d="M13,19 a1,0.75 0 0,0 10,0" fill="#000000" />
                  <rect
                    x="10"
                    y="14"
                    width="1.5"
                    height="2"
                    rx="1"
                    stroke="none"
                    fill="#000000"
                  />
                  <rect
                    x="24"
                    y="14"
                    width="1.5"
                    height="2"
                    rx="1"
                    stroke="none"
                    fill="#000000"
                  />
                </g>
              </g>
            </svg>
            <svg
              v-else-if="index % 9 === 7"
              viewBox="0 0 36 36"
              fill="none"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              width="60"
            >
              <title>Maya Angelou</title>
              <mask
                id="mask__beam"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="36"
                height="36"
              >
                <rect width="36" height="36" rx="72" fill="#FFFFFF" />
              </mask>
              <g mask="url(#mask__beam)">
                <rect width="36" height="36" fill="#fbbe9a" />
                <rect
                  x="0"
                  y="0"
                  width="36"
                  height="36"
                  transform="translate(-5 9) rotate(189 18 18) scale(1)"
                  fill="#faf5c8"
                  rx="36"
                />
                <g transform="translate(-5 4.5) rotate(-9 18 18)">
                  <path d="M13,19 a1,0.75 0 0,0 10,0" fill="#000000" />
                  <rect
                    x="10"
                    y="14"
                    width="1.5"
                    height="2"
                    rx="1"
                    stroke="none"
                    fill="#000000"
                  />
                  <rect
                    x="24"
                    y="14"
                    width="1.5"
                    height="2"
                    rx="1"
                    stroke="none"
                    fill="#000000"
                  />
                </g>
              </g>
            </svg>
            <svg
              v-else
              viewBox="0 0 36 36"
              fill="none"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              width="60"
            >
              <title>Elizabeth Blackwell</title>
              <mask
                id="mask__beam"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="36"
                height="36"
              >
                <rect width="36" height="36" rx="72" fill="#FFFFFF" />
              </mask>
              <g mask="url(#mask__beam)">
                <rect width="36" height="36" fill="#bda2a2" />
                <rect
                  x="0"
                  y="0"
                  width="36"
                  height="36"
                  transform="translate(1 7) rotate(243 18 18) scale(1)"
                  fill="#fad889"
                  rx="36"
                />
                <g transform="translate(-3 3.5) rotate(3 18 18)">
                  <path d="M13,19 a1,0.75 0 0,0 10,0" fill="#000000" />
                  <rect
                    x="11"
                    y="14"
                    width="1.5"
                    height="2"
                    rx="1"
                    stroke="none"
                    fill="#000000"
                  />
                  <rect
                    x="23"
                    y="14"
                    width="1.5"
                    height="2"
                    rx="1"
                    stroke="none"
                    fill="#000000"
                  />
                </g>
              </g>
            </svg>
          </div>

          <div
            class="flex-grow bg-white/10 p-3 rounded text-sm rsvp-item__body"
          >
            <div class="text-md font-medium mb-2 flex items-center d-flex">
              <div class="mr-2 name">
                {{ data.name }}
              </div>
              <div class="text-xs ml-auto opacity-60 timestamp">
                {{
                  $moment(data.created_at * 1000).format("DD MMM YYYY HH:mm")
                }}
              </div>
            </div>
            <div>{{ data.message }}</div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import LoveGift from "./LoveGift.vue";

export default {
  components: { LoveGift },
  data() {
    return {
      name: null,
      absence: "going",
      message: null,
      loading: false,
      errors: {},
      successMessage: false,
      rsvps: []
    };
  },
  methods: {
    validate() {
      this.errors = {};
      let valid = true;
      if (!this.name) {
        console.log(this.name);
        this.errors.name = "Nama harus diisi";
        valid = false;
      }
      return valid;
    },
    submit() {
      let valid = (valid = this.validate());
      const { name, absence, message } = this;
      const data = {
        id: this.$root.guest.id,
        name,
        absence,
        message
      };
      if (!valid) {
        return false;
      }
      this.loading = true;
      this.$root
        .postAttendance(data)
        .then(res => {
          this.successMessage = true;
          this.fetchRsvps();
          setTimeout(() => (this.successMessage = false), 5000);
        })
        .catch(res => {})
        .then(res => {
          this.loading = false;
        });
    },
    async fetchRsvps() {
      try {
        const { data: response } = await axios.get(
          `${this.$root.baseUrl}/rsvp`
        );
        this.rsvps = response.data;
      } catch (err) {
        // this.rsvps = []
      }
    }
  },
  mounted() {
    this.fetchRsvps();
  },
  computed: {
    rootGuestName() {
      return this.$root.guest.name;
    },
    rootAttendance() {
      return this.$root.guest.attendance;
    },
    rootMessage() {
      return this.$root.guest.message;
    }
  },
  watch: {
    rootGuestName() {
      this.name = this.rootGuestName;
    },
    rootAttendance() {
      this.absence = this.rootAttendance || "Hadir";
    },
    rootMessage() {
      this.message = this.rootMessage;
    }
  }
};
</script>

<style lang="scss" scoped>
.form-help {
  position: absolute;
  padding: 3px 10px;
  color: #fff;
  background: rgb(187, 46, 46);
  font-size: 0.7rem;
  box-shadow: 0 14px 26px -12px rgba(0, 123, 255, 0.42),
    0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 123, 255, 0.2);
  z-index: 1;
  margin-top: 2px;

  &:before {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid rgb(187, 46, 46);
    content: " ";
    position: absolute;
    top: 0;
    transform: translateY(-100%);
  }
}

.rsvps-list {
  margin-top: 2rem;
  max-height: 400px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0.25rem;
    height: 0.25rem;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-white/50;
    border-radius: 10px;
  }

  .rsvp-item {
    display: flex;
    margin-bottom: 1rem;

    > div:nth-child(2) {
      flex-grow: 1;
      padding: 0.5rem 1rem !important;
      background: rgba(255, 255, 255, 1);
      border: 1px solid #c8bfab;
    }

    &__body {
      .name {
        font-weight: bold;
      }

      .timestamp {
        font-size: 80%;
      }
    }
  }
}
</style>
