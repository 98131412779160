<template>
  <div id="app">
    <transition name="overflow-fade">
      <div
        v-if="$root.ready && overflow"
        class="ready-overflow"
        @click="openWebsite()"
      >
        <div class="text-center w-100 content">
          <div class="title">The Wedding</div>
          <span>of</span>
          <div class="mb-5 name">
            {{ wedding.man.surname }}
            <span style="margin-top: -1rem; margin-bottom: -1rem;">&</span>
            {{ wedding.woman.surname }}
          </div>

          <button
            class="text-uppercase btn btn-outline-primary"
            @click="openWebsite()"
          >
            Buka Undangan
          </button>
        </div>
      </div>
    </transition>

    <AGWAudio
      :file="music.file"
      :loop="music.loop"
      :autoplay="music.autoplay"
      ref="audio"
    />
    <AGWHero
      overlay
      :images="backgrounds"
      ref="hero"
      parallax
      target="#intro"
    />

    <div
      class="bg-white agw-content"
      :style="{ 'margin-top': `${$root.hero.height}px` }"
    >
      <AGWIntro
        id="intro"
        style="position:relative; box-shadow: 0 5px 15px rgb(175 150 105 / 34%)"
      />

      <AGWPawiwahan id="pawiwahan" class="bg-blue-light py-5" />
      <AGWMepandes id="mepandes" class="pad-y-60" :mepandes="mepandes" />

      <AGWCounter
        id="counter"
        class="pad-y-60"
        :background="background"
        :date="time.start"
      />
      <AGWGallery id="gallery" class="py-5" />
      <AGWMap
        :height="450"
        :url="map"
        :directionUrl="direction"
        :location="location"
        id="map"
      />
    </div>

    <div class="agw-content" style="background: transparent">
      <Himbauan />
      <attendance id="attendance" class="pad-y-60"></attendance>
    </div>

    <FooterCeremonyku ref="footer" />

    <transition name="slide-fade">
      <guest-box :floating="true" />
    </transition>

    <div class="snackbar" v-if="sw.updateExists">
      New version available!
      <button @click="refreshApp" class="btn">REFRESH</button>
    </div>
  </div>
</template>

<script>
const ImageHero1 =
  "https://ik.imagekit.io/c3remonyku/ardian-ayu/NCL00683.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1666361309590&tr=w-700";
const ImageHero2 =
  "https://ik.imagekit.io/c3remonyku/ardian-ayu/NCL00622.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1666361309574&tr=w-700";
const ImageHero3 =
  "https://ik.imagekit.io/c3remonyku/ardian-ayu/NCL03310.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1666361315650&tr=w-700";

import Audio from "@/assets/audio/soundtrack.mp3";

import AGWHero from "@/components/Hero/HeroTwo";
import AGWIntro from "@/components/Intro";
import AGWPawiwahan from "@/components/Pawiwahan";
import AGWMepandes from "@/components/Mepandes";
import AGWCounter from "@/components/Counter";
import AGWGallery from "@/components/Gallery/GalleryOne";
import AGWMap from "@/components/Maps";
import AGWAudio from "@/components/Audio";
import GuestBox from "@/components/GuestBox";
import Attendance from "@/components/Attendance";
import Himbauan from "./components/Himbauan.vue";
import FooterCeremonyku from "@/components/Footer/Ceremonyku";

const SESSION = [["2022-11-03 10:00", "Selesai"]];

export default {
  name: "app",
  components: {
    AGWHero,
    AGWIntro,
    AGWPawiwahan,
    AGWMepandes,
    AGWCounter,
    AGWGallery,
    AGWMap,
    AGWAudio,
    GuestBox,
    Attendance,
    Himbauan,
    FooterCeremonyku
  },
  data() {
    return {
      title: "Wedding",
      backgrounds: [ImageHero1, ImageHero2, ImageHero3],
      bgActive: 0,
      dividerHeight: 0,
      overflow: true,
      location: "Br. Tegeh, Desa Gadungan, Kec. Selemadeg Timur, Kab. Tabanan",
      map:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3946.1161844875637!2d115.07429931522212!3d-8.488082993897224!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x45e28940e13c3882!2zOMKwMjknMTcuMSJTIDExNcKwMDQnMzUuNCJF!5e0!3m2!1sen!2sid!4v1666360896974!5m2!1sen!2sid",
      direction: "https://goo.gl/maps/gwoPiiLoTVavtVvu7",
      time: {
        start: this.$moment("2022-11-03 10:00"),
        end: "Selesai"
      },
      wedding: {
        man: {
          name: "I Putu Agus Ardian Widiarta, Amd.Kep",
          surname: "Ardian",
          parents: "I Made Artana & Ni Wayan Winihati",
          description: "Putra pertama",
          address:
            "Br. Tegeh, Desa Gadungan, Kec Selemadeg Timur, Kab. Tabanan",
          photo:
            "https://ik.imagekit.io/c3remonyku/ardian-ayu/FOTO_SENDIRI_1.jpg?updatedAt=1666361385388&tr=w-400,h-500,fo-custom,cm-extract"
        },
        woman: {
          name: "Ni Putu Ayu Rumiaryantini, S.Pd",
          surname: "Ayu",
          parents: "I Nengah Rumintha & Ni Made Mustini",
          address: "Br. Kelodan, Desa Manggis, Kec. Manggis, Kab. Karangasem",
          description: "Putri pertama",
          photo:
            "https://ik.imagekit.io/c3remonyku/ardian-ayu/FOTO_SENDIRI_2.jpg?updatedAt=1666361366227&tr=w-498,h-622,fo-custom,cm-extract"
        }
      },
      mepandes: [
        // {
        //   name: "I Made Wahyudika Adi Putra"
        //   // image: 'https://ik.imagekit.io/ceremonyku/iyan-tini/SKP_4414e_XA6Xqi5UR_LiP.jpg?tr=w-671,h-671,fo-custom,cm-extract'
        // },
        // {
        //   name: "I Nyoman Adi Ariawan"
        //   // image: 'https://ik.imagekit.io/ceremonyku/iyan-tini/SKP_4414e_XA6Xqi5UR_LiP.jpg?tr=w-671,h-671,fo-custom,cm-extract'
        // },
        // {
        //   name: "Ni Kadek Nadia Putri Arimbawa"
        //   // image: 'https://ik.imagekit.io/ceremonyku/iyan-tini/SKP_4414e_XA6Xqi5UR_LiP.jpg?tr=w-671,h-671,fo-custom,cm-extract'
        // },
        // {
        //   name: "I Putu Arya Megadhana"
        //   // image: 'https://ik.imagekit.io/ceremonyku/iyan-tini/SKP_4414e_XA6Xqi5UR_LiP.jpg?tr=w-671,h-671,fo-custom,cm-extract'
        // }
      ],
      gallery: [],
      music: {
        file: Audio,
        autoplay: true,
        loop: true
      },
      sw: {
        refreshing: false,
        registration: null,
        updateExists: false
      }
    };
  },
  computed: {
    background() {
      return this.backgrounds[this.bgActive];
    },
    session() {
      const url = new URLSearchParams(window.location.search);
      const sesi = Number(url.get("sesi")) || 1;
      return sesi > SESSION.length ? 1 : sesi;
    }
  },
  mounted() {
    const [start, end] = SESSION[this.session - 1] || SESSION[0];

    this.time.start = this.$moment(start);
    this.time.end = end;

    this.dividerHeight = `calc(100vh - ${this.$refs.footer.offsetHeight}px)`;
    setInterval(() => {
      let active = this.bgActive + 1;
      if (active >= this.backgrounds.length) {
        active = 0;
      }

      this.bgActive = active;
    }, 5000);
  },
  created() {
    document.addEventListener("swUpdated", this.showRefreshUI, { once: true });
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (this.sw.refreshing) return;
        this.sw.refreshing = true;
        window.location.reload();
      });
    }
  },
  methods: {
    showRefreshUI(e) {
      this.sw.registration = e.detail;
      this.sw.updateExists = true;
    },
    refreshApp() {
      this.sw.updateExists = false;
      if (!this.sw.registration || !this.sw.registration.waiting) {
        return;
      }
      this.sw.registration.waiting.postMessage("skipWaiting");
    },
    openWebsite() {
      this.overflow = false;
      document.querySelector("#loading").classList.remove("active");
      if (this.$refs.audio) this.$refs.audio.play();
    }
  }
};
</script>

<style lang="scss" scoped>
.overflow-fade-enter-active {
  transition: all 1s ease;
}
.overflow-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.overflow-fade-enter,
.overflow-fade-leave-to {
  opacity: 0;
}
</style>
